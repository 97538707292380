/*******
 * Layout
 **/

* {
	box-sizing: border-box;
}

html, body {
	min-height: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	margin: 0;
	background: #E5E5E5;
	text-align: center;
}

.viewport {
	margin: 0 60px;
	position: relative;
	max-width: 1440px;
	width: 100%;
	display: inline-block;

	& > * {
		text-align: left;
	}

	main & {
		width: 100%;
		max-width: 1090px;
	}
}

.logo {
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
}

header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: block;
	height: 84px;
	overflow: hidden;
	background: #fff;

	.logo {
		background-image: url(images/logo-black.png);
		background-size: contain;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 127px;
		height: 48px;
	}

	.logo-vinyl {
		position: absolute;
		top: 0px;
		left: 0;
		height: 84px;
		width: 150px;
		font-size: 84px;
		line-height: 105%;
		text-decoration: none;
		color: $grey;
		svg path {
			fill: black;
		}
	}

	nav {
		ul {
			margin: 0 80px 0 0;
			padding: 0;
			float: right;
			list-style: none;

			li {
				margin: 0;
				padding: 0;
				float: left;
				a {
					display: block;
					color: $lightGrey;
					text-decoration: none;
					padding: 30px 0;
					margin: 0 20px;
					text-transform: uppercase;
					font-family: Poppins;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					position: relative;
					transition: color 0.2s;

					&:after {
						content: '';
						height: 4px;
						background: transparent;
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						transition: background 0.2s;
					}

					&.active,
					&:hover {
						color: $grey;

						&:after {
							background: $grey;
						}
					}
				}
			}
		}
	}
}

main {
	position: absolute;
	top: 84px;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;

	.fullscreen & {
		top: 0;
	}

	.head {
		background: #1A1B27;
		height: 252px;
		margin-bottom: -120px;
		position: relative;

		h1 {
			i {
				font-size: 22px;
				margin-left: 8px;
				cursor: pointer;

				&:before {
					color: $mediumGrey;
					transition: color 0.2s;
				}

				&:hover {
					&:before {
						color: #fff;
					}
				}
			}

			.playlist-cover {
				width: 80px;
				height: 80px;
				background: #ddd;
				border: 1px solid #fff;
				float: left;
				margin-right: 20px;
				transition: opacity 0.25s;
				cursor: pointer;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.viewport {
			padding-top: 60px;
		}
	}
}

// Small padding
.ps {
	padding: 4px 0;
}

.breadcrumb {
	position: absolute;
	top: 20px;
	left: 0;
	color: $mediumGrey;
	text-decoration: none;

	i {
		font-size: 18px;
		position: relative;
		top: 2px;
	}
}

.tabs {
	position: absolute;
	right: 0;
	top: 62px;

	.tab {
		margin-left: 4px;
		padding: 4px 14px;
		background: transparent;
		border-radius: 40px;
		color: #535466;
		text-decoration: none;
		transition: background 0.25s, color 0.25s;
		display: inline-block;

		&:hover,
		&.active {
			color: #fff;
			background: #2F3042;
		}
	}
}

.filters {
	margin-bottom: 12px;
	float: left;

	& > div {
		float: left;
		margin-right: 12px;
		position: relative;

		&:before {
			position: absolute;
			top: 6px;
			left: 10px;
			font-size: 16px;
		}

		&.select {
			& > div {
				height: 29px;

				& > .css-1s2u09g-control, & > .css-1pahdxg-control {
					min-height: 29px;

					.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
						padding: 4px;
					}
				}
			}
		}
	}
}

.buttons {
	margin-bottom: 12px;
	float: right;
	& > * {
		float: left;
		margin-left: 8px;
	}
}

.fields {
	.field {
		width: 25%;
		form & {
			width: 25%;
		}
		float: left;
		padding: 5px 16px 15px 0;
		label {
			min-height: 14px;
		}
		input, select {
			width: 100%;
			height: 30px;
		}
		input[disabled] {
			background-color: #F4F4F4;
		}
	}

	.wide-field {
		width: 50%;
		form & {
			width: 50%;
		}
		float: left;
		padding: 5px 16px 15px 0;
		label {
			min-height: 14px;
		}
		input, select {
			width: 100%;
			height: 30px;
		}
		textarea {
			width: 100%;
			min-height: 62px;
		}
		input[disabled], textarea[disabled] {
			background-color: #F4F4F4;
		}
	}

	.long-field {
		width: 100%;
		form & {
			width: 100%;
		}
		float: left;
		padding: 5px 0;
		label {
			min-height: 14px;
		}
		textarea {
			width: 100%;
			min-height: 62px;
		}
		input[disabled], textarea[disabled] {
			background-color: #F4F4F4;
		}
	}

	.pictures {
		form & {
			width: 100%;
		}
		float: left;
		padding: 5px 16px 15px 0;
		img {
			width: 12.5%;
		}
	}
}

.table-wrapper {
	background: #FFFFFF;
	border: 1px solid #E2E4E9;
	box-shadow: 0px 4px 16px rgba(26, 27, 39, 0.12);
	border-radius: 4px;
}

.block {
	padding: 32px;
	position: relative;

	background: #FFFFFF;
	border: 1px solid #E2E4E9;
	box-shadow: 0px 4px 16px rgba(26, 27, 39, 0.12);
	border-radius: 12px;

	.loader {
		position: absolute;
	}

	form & {
		padding-bottom: 17px;
	}

	&.above {
		z-index: 10;
	}

	h2 {
		margin-bottom: 14px;
	}
}

.wrapper {
	margin-top: 40px;
	margin-bottom: 80px;

	h2 {
		margin-bottom: 15px;
	}
}

.scheduler-wrapper {
	margin-top: 20px;
	padding: 30px !important;
	overflow: hidden;

	.rbc-header {
		padding-top: 3px;
	}

	.rbc-calendar {
		height: 750px !important;
	}

	.rbc-timeslot-group {
		min-height: 30px;
	}

	.styles-module_day-column__30McI .styles-module_title__2VBFp {
		font-size: 14px;
	}

	.rbc-allday-cell {
		display: none;
	}
	.rbc-time-view .rbc-header {
		border-bottom: none;
	}
}


.login {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	background-image: url(images/login.png);
	background-size: cover;
	background-position: center;
	background-color: #21202F;

	svg path {
		fill: white;
	}

	.block {
		min-height: 360px;
	}

	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	background: linear-gradient(152.29deg, #775CC4 12.99%, #4BA2F1 86.83%);
	// 	opacity: 0.6;
	// }

	.logo {
		background-image: url(images/logo-white.png);
		//mix-blend-mode: darken;
		background-size: contain;
		width: 230px;
		height: 87px;
		margin: 0 auto 105px;
		color: #FFFFFF;

		&:before {
			font-size: 140px;
		}
	}

	.form-wrapper {
		max-width: 453px;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.block {
			padding: 48px 36px;
		}

		h2 {
			margin-bottom: 40px;
		}

		.field {
			width: 100%;
			text-align: left;
			padding-right: 0;
		}

		form {
			button {
				margin-top: 20px;
				width: 50%;
				font-size: 16px;
			}
		}
	}
	.signin {
		display: none;
	}
}

.rodal {
	outline: none;

	.header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 44px;
		background-color: #EDEDED;
	}

	.rodal-close {
		top: 14px;
		right: 14px;
		outline: none;
	}

	.rodal-dialog {
		padding: 44px 0 0;
		overflow: hidden;
	}

	.popup-inner {
		padding: 24px;
		h2 {
			margin-bottom: 10px;
		}
		.actions {
			border-top: 0;
		}
	}

	.left {
		width: 510px;
		border-right: 1px solid #DADADA;
	}

	.columns {
		height: 455px;
	}

	.right {
		width: 280px;

		h2 {
			margin-bottom: 24px;
		}
	}

	.left, .right {
		height: 100%;
		padding: 24px;
		float: left;
	}

	.tabs {
		position: relative;
		top: 0;
		margin-bottom: 24px;

		.tab {
			padding: 8px 12px;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
			color: #81838A;
			margin-left: 0;
			margin-right: 5px;

			&.active, &:hover {
				color: #0077D6;
				background: #D3EAFD;
			}
		}
	}

	.rc-time-picker {
		width: 45%;
		margin-right: 5%;
	}

	h4 {
		text-transform: uppercase;
	}

	.field {
		margin-top: 12px;
		margin-bottom: 24px;
	}

	.radio {
		padding: 0;

		.partial {
			display: inline-block;
			margin-left: 7px;
			background: #EDEDED;
			padding: 4px 12px;
			border-radius: 4px;
			margin-top: -4px;
		}

		input[type=text] {
			padding: 2px 3px;
			width: 50px !important;
			text-align: center;
		}
	}

	.checkbox {
		display: block;
		margin-bottom: 4px;
		label {
			cursor: pointer;
			font-size: inherit;
			color: inherit;
			display: inline-block;
		}
	}

	.actions {
		border-top: 1px solid #DADADA;
		text-align: center;
		padding: 23px;

		button {
			margin: 0 5px;
		}
	}
}

.search.select {
	width: 180px;
}